var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('router-view',{attrs:{"name":"NavBar"}}),_c('router-view',{attrs:{"name":"StudioNavBar"}}),_c('v-content',{class:{
      'content-bg': ![
        'SignIn',
        'SignUp',
        'Dashboard',
        'Video',
        'Detail'
      ].includes(this.$route.name)
        ? true
        : false
    }},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }