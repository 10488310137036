var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","transition":"fab-transition","max-width":"800","id":"settings"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('div',{staticClass:"d-flex justify-space-between mb-5",attrs:{"id":"modal-header"}},[_c('v-card-title',{staticClass:"py-3"},[_vm._v("Settings")]),_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"order":"last","order-sm":"last","order-md":"first","order-lg":"first","order-xl":"first","cols":"12","sm":"12","md":"8","lg":"8"}},[_c('h3',{staticClass:"mb-6 mt-0 pt-0"},[_vm._v("Personal Information")]),_c('ValidationObserver',{ref:"personalInfoForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('ValidationProvider',{attrs:{"name":"Channel Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Channel Name","filled":"","dense":""},model:{value:(_vm.formData.channelName),callback:function ($$v) {_vm.$set(_vm.formData, "channelName", $$v)},expression:"formData.channelName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Email","filled":"","dense":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})]}}],null,true)}),_c('v-btn',{staticClass:"primary ",attrs:{"loading":_vm.loading.personalInfo,"type":"submit","depressed":""}},[_vm._v("Submit")])],1)]}}])}),_c('h3',{staticClass:"mb-6 mt-6"},[_vm._v("Change Password")]),_c('ValidationObserver',{ref:"passwordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitPassword)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('ValidationProvider',{attrs:{"name":"Current password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input-group--focused",attrs:{"append-icon":_vm.showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"type":_vm.showCurrentPassword ? 'text' : 'password',"name":"Current password","label":"Current Password","filled":"","dense":"","hint":"At least 5 characters"},on:{"click:append":function($event){_vm.showCurrentPassword = !_vm.showCurrentPassword}},model:{value:(_vm.formData.currentPassword),callback:function ($$v) {_vm.$set(_vm.formData, "currentPassword", $$v)},expression:"formData.currentPassword"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"New password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input-group--focused",attrs:{"append-icon":_vm.showNewPassword ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"type":_vm.showNewPassword ? 'text' : 'password',"name":"New password","label":"New Password","filled":"","dense":"","hint":"At least 5 characters"},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword}},model:{value:(_vm.formData.newPassword),callback:function ($$v) {_vm.$set(_vm.formData, "newPassword", $$v)},expression:"formData.newPassword"}})]}}],null,true)}),_c('v-btn',{staticClass:"primary",attrs:{"loading":_vm.loading.password,"type":"submit","depressed":""}},[_vm._v("Submit")])],1)]}}])})],1),_c('v-col',{staticClass:"text-center",attrs:{"order-sm":"1","cols":"12","sm":"12","md":"4","lg":"4"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.toggleShow}},[_vm._v("Upload Avatar")]),_c('my-upload',{attrs:{"field":"avatar","method":"PUT","headers":_vm.headers,"url":_vm.url,"width":200,"height":200,"noRotate":false,"img-format":"jpg","langType":"en"},on:{"crop-success":_vm.cropSuccess,"crop-upload-success":_vm.cropUploadSuccess,"crop-upload-fail":_vm.cropUploadFail},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}),_c('v-responsive',{staticClass:"mx-auto",staticStyle:{"border-radius":"50%"},attrs:{"width":"230"}},[(_vm.$store.getters.currentUser.photoUrl == 'no-photo.jpg')?_c('div',{staticClass:"px-12",attrs:{"id":"settings-image-placeholder"}},[_c('v-icon',[_vm._v("mdi-image-plus")])],1):_c('v-img',{attrs:{"height":"220","src":_vm.imgDataUrl}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }